import React, {useEffect, useState} from "react";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import Tooltip from "@amzn/meridian/tooltip"
import infoTokens from "@amzn/meridian-tokens/base/icon/help-knockout";
import Box from "@amzn/meridian/box";
import { css } from "emotion";
import Column from "@amzn/meridian/column";
import { getNumericalDisplayValueFromData, getStowMetrics } from "./Utils";
import { AnalyticEventKeys, MobileAnalyticsHelper } from "@amzn/dolphin-web-framework";
import { ANALYTICS_DESCRIPTION } from "./Constants";
import Card from "@amzn/meridian/card";

const StowMetricsView = (props) => {

    const FIFTEEN_MINUTES_IN_MILLIS = 15 * 60 * 1000;
    const ONE_MINUTE_IN_MILLIS = 60 * 1000;

    const [data, setData] = useState(null);
    const [lastUpdatedInMins, setLastUpdatedInMins] = useState({isDataFetchedOnSameMin: false, valueInMins: 0});

    const metricContainerRow = css({
        margin: "2px !important",
    });

    const metricText = css({
        marginRight: "5px !important",
        textAlign: "left"
    });

    const titleText = css({
        marginBottom: "0px !important"
    })

    const noMarginRow = css({
        marginBottom: "0 !important"
    });

    useEffect(() => {

        const START_TIME = Date.now();

        function updateStowMetrics(isDataFetchedOnSameMin) {
            if (props.stage && props.region) {
                getStowMetrics(props.stage, props.region, setData);
                setLastUpdatedInMins({isDataFetchedOnSameMin, valueInMins: 0});
            }
        }

        // Update the metrics for the first time.
        updateStowMetrics(false);

        // Update metrics every 15 mins.
        const updateStowMetricsInterval = setInterval(() => {
            updateStowMetrics(true);
        }, FIFTEEN_MINUTES_IN_MILLIS);

        // Update the lastUpdatedInMins value every min.
        const updateLastUpdatedInMinsInterval = setInterval(() => {
            setLastUpdatedInMins((previousObj) => {
                if (previousObj.isDataFetchedOnSameMin) {
                    return {
                        isDataFetchedOnSameMin: false,
                        valueInMins: 0
                    }
                }
                return {
                    isDataFetchedOnSameMin: false,
                    valueInMins: previousObj.valueInMins + 1
                }
            });
        }, ONE_MINUTE_IN_MILLIS);

        function recordMetric() {
            MobileAnalyticsHelper.processAnalytics(START_TIME, ANALYTICS_DESCRIPTION.STOW_PROCESS_METRIC,
                null, AnalyticEventKeys.Modules.PACKAGE_STOW, null, null,
                AnalyticEventKeys.Events.USER_VIEWED_STOW_PACKAGE_INSIGHT, false, null, null);
        }

        return () => {
            recordMetric();
            clearInterval(updateStowMetricsInterval);
            clearInterval(updateLastUpdatedInMinsInterval);
        }
    }, []);

    const renderStowPPH = () => {
        return (
            <Column className={noMarginRow}>
                <Row className={metricContainerRow} alignmentHorizontal={"start"}>
                    <Text className={metricText} type={"d50"}>
                        {getNumericalDisplayValueFromData(data.associateStowMetrics.pph)}
                    </Text>
                </Row>
                <Row alignmentHorizontal={"start"}>
                    <Text className={metricText} type={"b300"}>{`Packages per hour`}</Text>
                    <Tooltip position={"start"} title={"The number of packages stowed per hour"}>
                        <Icon tokens={infoTokens} color="undefined" />
                    </Tooltip>
                </Row>
            </Column>
        )
    }

    const renderFASF = () => {
        return (
            <Column className={noMarginRow}>
                <Row className={metricContainerRow} alignmentHorizontal={"start"}>
                    <Text className={metricText} type={"d50"}>
                        {getNumericalDisplayValueFromData(data.associateStowMetrics.fsaf)}
                    </Text>
                </Row>
                <Row alignmentHorizontal={"start"}>
                    <Text className={metricText} type={"b300"}>{`FSAF`}</Text>
                    <Tooltip position={"start"} title={"First scan attempt failures"}>
                        <Icon tokens={infoTokens} color="undefined" />
                    </Tooltip>
                </Row>
            </Column>
        )
    }

    const renderInfoIcons = () => {
        return (
            <Row widths={["grid-6", "grid-6"]} alignmentHorizontal={"end"} width={"100%"} className={noMarginRow}>
                <Row alignmentHorizontal={"end"}>
                    <Tooltip position={"top"} title={"Your speed of stowing in packages stowed per hour"}>
                        <Icon tokens={infoTokens} color="undefined" />
                    </Tooltip>
                </Row>
                <Row alignmentHorizontal={"end"}>
                    <Tooltip position={"top"} title={"First scan attempt failures"}>
                        <Icon tokens={infoTokens} color="undefined" />
                    </Tooltip>
                </Row>
            </Row>
        )
    }

    const renderLastUpdatedText = () => {
        const lastUpdatedInMinsText = lastUpdatedInMins.valueInMins === 1 ? `1 min` : `${lastUpdatedInMins.valueInMins} mins`;
        return <Text type={"b400"} className={titleText}>{`Summary (Last updated ${lastUpdatedInMinsText} ago)`}</Text>;
    }

    const renderStowMetrics = () => {
        // If required props are not sent, display nothing.
        if (!props.stage || !props.region) {
            return null;
        }

        // Display nothing until data is not fetched correctly.
        if (!data || !data.associateStowMetrics) {
            return null;
        }

        return (
            <Column width={"100%"} spacingInset={"200"}>
                {renderLastUpdatedText()}
                <Card width={"100%"} spacingInset={"300"}>
                    {renderStowPPH()}
                    {renderFASF()}
                </Card>
            </Column>
        )
    }

    return (
        renderStowMetrics()
    );
};

export default StowMetricsView;